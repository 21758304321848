<template>
  <div class="other-container">
    <div class="title">相似文献</div>
    <ul>
      <li v-for="(item, i) in otherList" :key="i">
        <span>[{{ i + 1 }}]</span>
        <span class="titleBox clickSpan" @click="toliterature(item)">{{
          item.title
        }}</span>
        <span
          class="clickSpan"
          @click="goAuther(item.firstAuthorId)"
          v-if="item.firstAuthorId"
          >{{ item.firstAuthor }}</span
        >
        <template v-if="item.authorResultList">
          <span
            :style="i > 2 ? 'margin: 0' : ''"
            class="clickSpan"
            @click="goAuther(v.authorId)"
            v-for="(v, i) in item.authorResultList"
            :key="i"
          >
            {{ i > 2 ? "" : v.authorName }}
            {{
              (i === 2 && item.authorResultList.length >= 4) ||
              (item.authorResultList.length === 3 && item.communicationAuthorId)
                ? " 等"
                : ""
            }}
          </span>
        </template>
        <span
          class="clickSpan"
          @click="goAuther(item.communicationAuthorId)"
          v-if="item.communicationAuthorId && item.authorResultList.length < 3"
          >{{ item.communicationAuthor }}</span
        >
        <span
          class="clickSpan"
          @click="handleFlag(item.periodicalId)"
          v-if="item.periodicalId"
          >{{ item.periodical }}</span
        >
        <span v-if="item.period">{{ item.period }}</span>
      </li>
    </ul>
    <Modal
      v-model="flag"
      @on-ok="ok"
      title="即将离开百家在线"
      ok-text="继续访问"
    >
      <p>您即将进入中文知识网，点击“继续访问”可查看具体内容。</p>
      <p>
        中文知识网是国家知识服务平台融合出版分平台，是中文集团旗下子公司运营的知识服务平台。中文知识网拥有百家在线、百科在线、同行评议等子平台。请放心跳转。
      </p>
    </Modal>
    <Modal
      v-model="flag1"
      @on-ok="ok1"
      title="即将离开百家在线"
      ok-text="继续访问"
    >
      <p>您即将进入中文知识网，点击“继续访问”可查看具体内容。</p>
      <p>
        中文知识网是国家知识服务平台融合出版分平台，是中文集团旗下子公司运营的知识服务平台。中文知识网拥有百家在线、百科在线、同行评议等子平台。请放心跳转。
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  props: {
    otherList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      flag: false,
      flag1: false,
      id: "",
      id1: "",
    };
  },
  methods: {
    handleFlag(id) {
      this.flag = true;
      this.id = id;
    },
    handleFlag1(id) {
      this.flag1 = true;
      this.id1 = id;
    },
    toliterature(item) {
      let url;
      if (item.articleType === 0) {
        if (item.type === 0) {
          url = this.$router.resolve({
            path: "paperDetail",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 1) {
          url = this.$router.resolve({
            path: "conferencepapers",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 2) {
          url = this.$router.resolve({
            path: "conferenceppt",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 4) {
          url = this.$router.resolve({
            path: "conferenceother",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        }
      } else if (item.articleType === 1) {
        url = this.$router.resolve({
          path: "patentdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 2) {
        url = this.$router.resolve({
          path: "monographdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 3) {
        url = this.$router.resolve({
          path: "reportdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 4) {
        url = this.$router.resolve({
          path: "standarddetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 5 && item.type === 0) {
        url = this.$router.resolve({
          path: "informationdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      }
      window.open(url.href, "_blank");
    },
    goAuther(id) {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: {
          id,
        },
      });
      window.open(url.href, "_blank");
    },
    ok() {
      window.open(`https://www.baikezaixian.com/details?ids=${this.id}`);
    },
    ok1() {
      window.open(`https://www.baikezaixian.com/details?ids=${this.id1}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.other-container {
  width: 1000px;
  // height: 429px;
  margin-top: 45px;
  .title {
    font-size: 24px;
    color: #00a4ff;
  }
  ul {
    margin-top: 29px;
    li {
      margin: 20px 0;
      span {
        display: inline-block;
        margin: 0 5px;
        overflow: hidden;
      }
      .clickSpan {
        cursor: pointer;
        color: #00a4ff;
      }
      .titleBox {
        max-width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
